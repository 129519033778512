import React from "react";
import _ from "lodash";
import axios from "axios";
import moment from 'moment';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import TableCrud from "../TableCrud";
import Loading from "../Miscellaneous/Loading";
import {getMonth} from "../../../scripts/global";

class MediaBuys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
            limit: 30,
            page: 0,
            apiUrl: "",
            showAPIUrlPopup: false
        };
    }

    async componentDidMount() {
        // load db
        const {data} = await axios.get(
            process.env.SERVER_ENDPOINT + "/click_orders"
        );

        const click_orders = _.map(data.click_orders, itm => {
            const fulfillmentPercentage = (itm.clicks_fulfilled/itm.clicks_ordered) * 100;
            const pace = this.calculatePace(itm)
            return {
                ...itm,
                client_campaign_name: itm.client_campaign_name || '(Untitled)',
                fulfillmentPercentage: fulfillmentPercentage,
                pace: pace
        }}).reverse();

        console.log("myData", {data});
        this.setState({data: click_orders});
    }
    
    calculatePace(row) {
        const current_time = moment.utc(new Date()).valueOf()
        const beginning_of_campaign = moment.utc(row.start_date).valueOf()
        const end_of_campaign = moment.utc(row.end_date).valueOf()

        const fulfillment_percentage = (current_time - beginning_of_campaign) / (end_of_campaign - beginning_of_campaign)
        if(end_of_campaign <= current_time) {
            return row.clicks_fulfilled / row.clicks_ordered * 100;
        }
        
        const clicks_expected = fulfillment_percentage * row.clicks_ordered;
        const score = row.clicks_fulfilled / clicks_expected * 100;
        return score;
    }

    render() {
        const {data} = this.state;

        const headers = [
            {
                label: "Name",
                fieldName: "client_campaign_name",
                minWidth: 200,
                onClick: itm =>
                    (window.location.href = `/media-planner/media-order-request?id=${itm.click_order_id}`)
            },
            {
                label: "Client",
                fieldName: "client_name",
                minWidth: 200
            }, {
                label: "URL Method",
                fieldName: "url_method",
                minWidth: 200
            }, {
                label: "Clicks Ordered",
                fieldName: "clicks_ordered",
                minWidth: 200
            }, {
                label: "Clicks Fulfilled",
                fieldName: "clicks_fulfilled",
                minWidth: 200
            }, {
                label: "Fulfillment %",
                fieldName: `fulfillmentPercentage`,
                minWidth: 200,
                type: "float-%"
            }, {
                label: "Pace",
                fieldName: "pace",
                minWidth: 200,
                type: "float-%"
            }, {
                label: "Leads Per Day",
                fieldName: "leads_per_day",
                minWidth: 200
            }, {
                label: "Daily Budget",
                fieldName: "daily_budget",
                minWidth: 200,
                type: "money"
            },
            {
                label: "Start Date",
                fieldName: "start_date",
                minWidth: 200,
                type: "DateNoTime"
            },
            {
                label: "Fulfill By",
                fieldName: "end_date",
                minWidth: 200,
                type: "DateNoTime"
            },
            {
                label: "Status",
                fieldName: "status",
            },
            {
                label: "CPL",
                fieldName: "client_cpl",
                type: "money"
            },
            {
                label: "Post Back URL",
                fieldName: "post_back_url",
                type: "template",
                minWidth:200,
                default: "Get URL",
                onClick: data => {
                    this.setState({
                        showAPIUrlPopup: true,
                        apiUrl: data["post_back_url"]
                    });
                }
            }

        ];

        return (
            <>
                {!data ? (
                    <Loading/>
                ) : (
                    <div>
                        <Dialog open={this.state.showAPIUrlPopup}>
                            <DialogTitle>API URL</DialogTitle>
                            <DialogContent style={{width: 500}}>
                                <TextField
                                    variant="outlined"
                                    multiline
                                    style={{width: "100%"}}
                                    rowsMax="4"
                                    value={this.state.apiUrl}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    color="primary"
                                    onClick={() => this.setState({showAPIUrlPopup: false})}
                                >
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <TableCrud
                            headers={headers}
                            hideCrud
                            hideCSVDownload
                            hideEmailAction
                            hideDownloadAction
                            hideFilter
                            href="/media-planner/media-order-request"
                            data={data}
                            idField="click_order_id"
                            name="Co-Registration Order"
                            pagination={{
                                page: this.state.page,
                                limit: this.state.limit,
                                setLimit: limit => this.setState({limit}),
                                setPage: page => this.setState({page})
                            }}
                            actionButtonText="New Order"
                        />
                    </div>
                )}
            </>
        );
    }
}

export default MediaBuys;
